import styles from "./bottomPage.module.css";
import logo from "../../static/images/R.png";
import { BiLogoGmail } from "react-icons/bi";
import { RiLinkedinFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa6";

const BottomPage = () => {
  return (
    <div className={styles.topContainer}>
      <div className={styles.container}>
        <img src={logo} alt="logo" className={styles.logo} />
        <p className={styles.para1}>Embrace the Extraordinary</p>
        <div>
          <span
            onClick={() =>
              window.open(
                `https://mail.google.com/mail/?view=cm&fs=1&to=ram.sewak0015@gmail.com`,
                "_blank"
              )
            }
          >
            <BiLogoGmail id={styles.gmail} />
          </span>
          <span
            onClick={() =>
              window.open(
                `https://linkedin.com/in/ram-sewak-6179881b4/`,
                "_blank"
              )
            }
          >
            <RiLinkedinFill id={styles.linkedin} />
          </span>
        </div>
      </div>
      <hr />
      <p className={styles.para2}>Handcrafted by me ©Ramsewak2024</p>
    </div>
  );
};

export default BottomPage;
