import styles from "./summary.module.css";

const Summary = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Hi, I’m Ramsewak. Nice to meet you.</h2>
      <div className={styles.paraContainer}>
        <p className={styles.para}>
          I build pixel-perfect, engaging, and accessible digital experiences.
        </p>
        <p className={styles.para}>
          I’m Dynamic and detail-oriented web developer with a strong background
          in designing, coding, and maintaining responsive websites and
          applications.
        </p>
        <p className={styles.para}>
          Proficient in front-end technologies such as HTML, CSS, JavaScript and
          React, as well as back-end framework like Express Js.
        </p>
        <p className={styles.para}>
          Passionate about staying current with emerging web technologies and
          best practices to deliver engaging and user-friendly digital
          experiences.
        </p>
      </div>
    </div>
  );
};

export default Summary;
