import styles from "./aboutMeCard.module.css";

const AboutMeCard = ({ details }) => {
  const { p1, p2 } = details;

  return (
    <>
      <div className={styles.content}>
        <h1 className={styles.name}>
          {p1}
          <br />
          {p2}
        </h1>
      </div>
    </>
  );
};

export default AboutMeCard;
