import StartProject from "../startProject/startProject";
import styles from "./Collaboration.module.css";
import { BiSolidMessageDetail } from "react-icons/bi";

const Collaboration = () => {
  return (
    <div className={styles.topContainer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.heading}>
            Interested in collaborating with me?
          </h1>
          <p className={styles.para}>
            I’m always open to discuss product design work or partnership
            opportunities.
          </p>
          <span
            onClick={() =>
              window.open(
                `https://mail.google.com/mail/?view=cm&fs=1&to=ram.sewak0015@gmail.com`,
                "_blank"
              )
            }
          >
            <button className={styles.btn}>
              <BiSolidMessageDetail />
              &nbsp;Start a conversation
            </button>
          </span>
        </div>
      </div>
      <StartProject />
    </div>
  );
};

export default Collaboration;
