import styles from "./navbar.module.css";
import logo from "../../static/images/R.png";
import { Link, Outlet } from "react-router-dom";
import BottomPage from "../bottomPage/bottomPage";

const Navbar = () => {
  return (
    <>
      <div className={styles.container}>
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>
        <nav className={styles.navbar}>
          <span
            onClick={() =>
              window.open(
                `https://mail.google.com/mail/?view=cm&fs=1&to=ram.sewak0015@gmail.com`,
                "_blank"
              )
            }
          >
            <button className={styles.navBtn}>Say Hello</button>
          </span>
        </nav>
      </div>
      <Outlet />
      <BottomPage />
    </>
  );
};

export default Navbar;
