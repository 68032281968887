import styles from "./myWork.module.css";
import rvsmLogo from "../../static/images/Logo-Removebg-RVSM.png";
import { MdOutlineArrowOutward } from "react-icons/md";
import { ImArrowDown } from "react-icons/im";

const MyWork = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>My Recent Work</h1>
      <p className={styles.heaadingPara}>
        Here are a few past projects I've worked on. Want to see more? Email me.
      </p>
      <div className={styles.workExpDiv}>
        <p className={styles.forsmallscreen}>
          Click on the card <ImArrowDown />
        </p>
        <div className={styles.rvsm}>
          <img src={rvsmLogo} alt="rvsmLogo" className={styles.rvsm_Logo} />
          <div className={`${styles.companySummary}`}>
            <p>A telecommunication company</p>
            <span
              onClick={() => window.open(`https://www.rvsm.co.in`, "_blank")}
            >
              <button className={styles.visitBtn}>
                Visit Website&nbsp;
                <MdOutlineArrowOutward />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWork;
