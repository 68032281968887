import styles from "./startProject.module.css";
import { FaHandshake } from "react-icons/fa6";

const StartProject = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Start a project</h1>
        <p className={styles.para}>
          Interested in working together? Please let me know a time that works
          for you .
        </p>
        <span
          onClick={() =>
            window.open(
              `https://mail.google.com/mail/?view=cm&fs=1&to=ram.sewak0015@gmail.com`,
              "_blank"
            )
          }
        >
          <button className={styles.btn}>
            <FaHandshake />
            &nbsp;Let's do this
          </button>
        </span>
      </div>
    </div>
  );
};

export default StartProject;
