import styles from "./computer.module.css";
import comp from "../../static/images/hero-devices.svg";
const ComputerImage = () => {
  return (
    <div className={styles.container}>
      <img src={comp} alt="computer images" />
    </div>
  );
};

export default ComputerImage;
