import styles from "./aboutme.module.css";
import profilePic from "../../static/images/ram.jpg";
import background from "../../static/images/laptop.jpg";
import Carousel from "../carousel/carousel";
import AboutMeCard from "./aboutMeCard";

const AboutMe = () => {
  const images = [
    { p1: "I'm", p2: " Ramsewak" },
    { p1: "Full Stack Web", p2: " Developer" },
    {
      p1: "I design and code",
      p2: " beautifully",
    },
    { p1: "I love what", p2: " I do" },
  ];
  return (
    <div className={styles.container}>
      <img
        src={background}
        alt="background image"
        className={styles.backgroundImage}
      />
      <Carousel images={images}>
        <AboutMeCard />
      </Carousel>
      <img src={profilePic} alt="profile pic" className={styles.profilePic} />
    </div>
  );
};

export default AboutMe;
