import styles from "./technologies.module.css";
import { FaLaptopCode } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa6";

const Technologies = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.fronendCard}>
          <span className={styles.iconDiv}>
            <FaLaptopCode />
          </span>
          <h1 className={styles.content1_heaading1}>Frontend Developer</h1>
          <p className={styles.content1_para}>
            I like to code things from scratch, and enjoy bringing ideas to life
            in the browser.
          </p>
          <h2 className={styles.content1_heaading2}>Languages I speak:</h2>
          <p className={styles.content1_para}>HTML, CSS, Javascript, Git</p>
          <h2 className={styles.content1_heaading2}>
            Dev Technologies and Tools:
          </h2>
          <p className={styles.content1_para}>
            React, VS Code, Bootstrap, Github
          </p>
        </div>
        <div className={styles.backendCard}>
          <span className={styles.iconDiv}>
            <FaFileCode />
          </span>
          <h1 className={styles.content1_heaading1}>Backend Developer</h1>
          <p className={styles.content1_para}>
            With a solid foundation in server-side programming, I leverage
            language like Javascript and framework such as Express Js to build
            high-performance applications.
          </p>
          <h2 className={styles.content1_heaading2}>Languages I speak:</h2>
          <p className={styles.content1_para}>Javascript, Python, Git</p>
          <h2 className={styles.content1_heaading2}>
            Dev Technologies and Tools:
          </h2>
          <p className={styles.content1_para}>
            Node js, Express Js, VS Code, Github
          </p>
        </div>

        {/* <div className={styles.content1}>
          <span className={styles.iconDiv}>
            <FaLaptopCode />
          </span>

          <h1 className={styles.content1_heaading1}>Frontend Developer</h1>
          <p className={styles.content1_para}>
            I like to code things from scratch, and enjoy bringing ideas to life
            in the browser.
          </p>
          <h2 className={styles.content1_heaading2}>Languages I speak:</h2>
          <p className={styles.content1_para}>
            HTML, CSS, Javascript, Python, Git
          </p>
          <h2 className={styles.content1_heaading2}>
            Dev Technologies and Tools:
          </h2>
          <p className={styles.content1_para2}>React</p>
          <p className={styles.content1_para2}>VS Code</p>
          <p className={styles.content1_para2}>Bootstrap</p>
          <p className={styles.content1_para2}>Github</p>
        </div> */}
        {/* <div className={styles.content1}>
          <span className={styles.iconDiv}>
            <FaFileCode />
          </span>

          <h1 className={styles.content1_heaading1}>Backend Developer</h1>
          <p className={styles.content1_para}>
            With a solid foundation in server-side programming, I leverage
            language like Javascript and framework such as Express Js to build
            high-performance applications.
          </p>
          <h2 className={styles.content1_heaading2}>Languages I speak:</h2>
          <p className={styles.content1_para}>Javascript, Python, Git</p>
          <h2 className={styles.content1_heaading2}>
            Dev Technologies and Tools:
          </h2>
          <p className={styles.content1_para2}>Express Js</p>
          <p className={styles.content1_para2}>Node Js</p>
          <p className={styles.content1_para2}>VS Code</p>
          <p className={styles.content1_para2}>Github</p>
        </div>
        <div className={styles.content1}>
          <span className={styles.iconDiv}>
            <FaLaptopCode />
          </span>

          <h1 className={styles.content1_heaading1}>Frontend Developer</h1>
          <p className={styles.content1_para}>
            I like to code things from scratch, and enjoy bringing ideas to life
            in the browser.
          </p>
          <h2 className={styles.content1_heaading2}>Languages I speak:</h2>
          <p className={styles.content1_para}>
            HTML, CSS, Javascript, Python, Git
          </p>
          <h2 className={styles.content1_heaading2}>
            Dev Technologies and Tools:
          </h2>
          <p className={styles.content1_para2}>React</p>
          <p className={styles.content1_para2}>VS Code</p>
          <p className={styles.content1_para2}>Bootstrap</p>
          <p className={styles.content1_para2}>Github</p>
        </div> */}
      </div>
    </div>
  );
};

export default Technologies;
