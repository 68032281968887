import AboutMe from "../about me/aboutme";
import BottomPage from "../bottomPage/bottomPage";
import Collaboration from "../Collaboration/Collaboration";
import ComputerImage from "../computer image/computer";
import MyWork from "../myWork/myWork";
import Summary from "../summary/summary";
import Technologies from "../technologies/technologies";
import styles from "./home.module.css";

const Home = () => {
  return (
    <>
      <div className={styles.container}></div>
      <AboutMe />
      <ComputerImage />
      <Summary />
      <Technologies />
      <MyWork />
      <Collaboration />
    </>
  );
};

export default Home;
