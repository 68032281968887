import { useState, useEffect } from "react";
import React from "react";
import styles from "./carousel.module.css";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

const CarouselIndicators = ({ images, activeIndex, onClick }) => {
  return (
    <div className={styles.indicators}>
      {images.map((_, index) => (
        <button
          key={index}
          className={`${styles.indicator} ${
            index === activeIndex ? styles.activeIndicator : ""
          }`}
          onClick={() => onClick(index)}
        ></button>
      ))}
    </div>
  );
};

const Carousel = ({ images, children, interval = 3000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    setActiveIndex((prevState) =>
      prevState === images.length - 1 ? 0 : prevState + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevState) =>
      prevState === 0 ? images.length - 1 : prevState - 1
    );
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, interval);

    return () => clearInterval(intervalId);
  }, [interval]);

  return (
    <>
      <div className={styles.container}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { details: images[activeIndex] });
        })}

        {/* <button
          className={`${styles.btn} ${styles.leftBtn}`}
          onClick={prevSlide}
        >
          <FaArrowLeft />
        </button>
        <button
          className={`${styles.btn} ${styles.rightBtn}`}
          onClick={nextSlide}
        >
          <FaArrowRight />
        </button> */}

        {/* <CarouselIndicators
          images={images}
          activeIndex={activeIndex}
          onClick={goToSlide}
        /> */}
      </div>
    </>
  );
};

export default Carousel;
